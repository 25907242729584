<template>
 <div class="s_user_list_get_code_pop">
    <el-dialog title="获取验证码" :close-on-click-modal="false" width="300px" :visible.sync="show">
      <div class="s-user-list-get-code-box g-flex-align-center">
        <p class="s-user-list-get-code-box-title">验证码为:</p>
        <p class="s-user-list-get-code-box-value">{{code}}</p>
        <el-button size="mini" type="success" @click="copyClick">复制</el-button>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <el-button type="primary" @click="show = false">确定</el-button>
      </div>
    </el-dialog>
 </div>
</template>

<script>
import { apiGetUserMobileCode } from '@/utils/api.js'
export default {
  data() {
    return {
      show: false,
      mobile: '',
      code: ''
    }
  },
  methods: {
    // 复制
    copyClick() {
      this.$copyText(this.code).then(() => {
        this.$message.success({
          type: 'success',
          message: '复制成功'
        });
      },  () => {
        this.$message.error('复制失败,请重试');
      })
    },
    // 
    async apiGetUserMobileCodeHandel() {
      const { success, data } = await apiGetUserMobileCode({ mobile:this.mobile })
      if(!success) return
      this.$message.success('获取成功!')
      this.code = data.mobile_code
    },
    onShow(obj) {
      if(obj.mobile) {
        this.mobile = obj.mobile
        this.apiGetUserMobileCodeHandel()
      }
      this.show = true
    },
    onClose() {
      this.mobile = ''
      this.code = ''
      this.show = false
    }
  }
}
</script>

<style lang='scss'>
.s_user_list_get_code_pop {
  .s-user-list-get-code-box {
    .s-user-list-get-code-box-title {
      padding-right: 5px;
    }
    .s-user-list-get-code-box-value {
      padding-right: 20px;
      font-size: 16px;
    }
  }
}
</style>