<template>
  <div class="c_pingbi_time_pop">
    <el-dialog title="加入/移出黑名单" :close-on-click-modal="false" :visible.sync="show">
        <el-form size="small" :model="form" label-width="120px">

            <el-form-item label="拉黑单位:">
              <el-select v-model="form.type">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"/>
              </el-select>
            </el-form-item>
            <el-form-item label="拉黑时长:">
              <el-date-picker v-model="form.time" type="datetime" :picker-options="pickerOptions" placeholder="选择日期时间"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <p class="g-red">移出黑名单可把拉黑时长设置为当前时间</p>
            </el-form-item>
            <el-form-item label="拉黑原因:">
              <el-input placeholder="请输入拉黑原因" type="textarea" v-model="form.remark" :autosize="{ minRows: 4, maxRows: 10 }" maxlength="100" show-word-limit></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="show = false">取 消</el-button>
          <el-button type="primary" @click="apiAddUserBlockHandel">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiAddUserBlock } from '@/utils/api.js'
export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < (Date.now() - 86400000);
        }
      },
      seeStatus:1,
      show: false,

      form: {
        id: '',
        type: 1,
        black_time: '',
        time: '',
        remark: ''
      },
      options: [
        {
          value: 1,
          label: '该用户'
        },
        {
          value: 2,
          label: '该用户及推广买家'
        }],
    }
  },
  methods: {
    // 加入黑名单
    async apiAddUserBlockHandel() {
      let loading = this.$loading()
      console.log(this.form)
      console.log(typeof this.form.time)
      if((typeof this.form.time) == 'object') this.form.black_time = parseInt(this.form.time.getTime() / 1000) 
      else this.form.black_time = parseInt(this.form.time / 1000)
      const { success, data } = await apiAddUserBlock(this.form).catch(() => loading = false)
      loading.close()
      if(!success) return
      this.$message.success(data.msg)
      this.$emit('emitSaveSuccess')
      this.show = false
    },
    // status //1 审核 2查看
    onShow(data, status) {
      console.log(status)
      this.seeStatus = status
      this.form.id = data.id
      this.form.type = data.type
      let now = parseInt(new Date().getTime() / 1000)
      if(data.black_time < now) this.form.time = ''
      else this.form.time = data.black_time * 1000
      this.show = true
    },
    onClose() {
      this.show = false
    },
  }
}
</script>

<style lang='scss'>

</style>