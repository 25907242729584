<template>
  <div class="c_user_list_change_money_pop">
    <el-dialog :title="seeStatus == 1 ? '修改本金余额': '修改佣金余额'" width="600px" :close-on-click-modal="false" :visible.sync="show">
        <el-form size="small" :model="form" label-width="150px">
            <el-form-item label="修改类型:">
              <span>{{seeStatus == 1 ? '本金余额': '佣金余额'}}</span>
            </el-form-item>
            <el-form-item label="增加/减少金额:">
               <el-input-number size="medium" v-model="form.money" :precision="2" :step="1"></el-input-number>
            </el-form-item>
            <el-form-item label="修改标题:">
              <el-row>
                <el-col :span="18">
                <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" placeholder="请输入修改标题" v-model="form.title"></el-input>
                </el-col>
              </el-row>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="show = false">取 消</el-button>
          <el-button type="primary" @click="apiEditBuyMoneyHandel">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiEditBuyMoney } from '@/utils/api.js'
export default {
  data() {
    return {
      seeStatus:1,
      show: false,

      form: {
        id: '',
        type: 1,
        money: 0,
        title: ''
      },
    }
  },
  methods: {
    // 修改买家金额
    async apiEditBuyMoneyHandel() {
      console.log(this.form.money)
      if(!this.form.title) return this.$message.error('请填写修改标题！')
      let loading = this.$loading()
      const { success, data } = await apiEditBuyMoney(this.form).catch(() => loading.close())
      loading.close()
      if(!success) return
      this.$message.success(data.msg)
      this.show = false
      this.$emit('emitSaveSuccess')
      console.log(data)
    },

    // status //1 修改本金余额 2修改佣金余额
    onShow(data, status) {
      this.form.id = data.id
      this.seeStatus = status
      this.form.type = status
      this.form.money = 0
      this.form.title = ''
      this.show = true
    },
  }
}
</script>

<style lang='scss'>
</style>