<template>
  <el-card class="v_user_list">
    <div class="g-flex"  slot="header">
      <span>用户列表</span>
      <div class="g-flex-justify-end g-flex-1">
        <el-button size="mini" type="success" @click="addUser">新增用户</el-button>
      </div>
    </div>
    <!-- 搜索 -->
    <el-form :inline="true" size="mini" :model="query">
      <el-form-item label="实名状态">
        <el-select v-model="query.real_status" @change="getList()">
          <el-option label="全部" value="ALL"></el-option>
          <el-option label="未实名" value="0"></el-option>
          <el-option label="审核中" value="1"></el-option>
          <el-option label="已实名" value="2"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="用户状态">
        <el-select v-model="query.status" @change="getList()">
          <el-option label="全部" value="ALL"></el-option>
          <el-option label="正常" value="1"></el-option>
          <el-option label="禁用" value="0"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="时间：">
          <el-date-picker v-model="query.date_time" @change="getList()" type="datetimerange"
                          :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker>
      </el-form-item>  -->
      <el-form-item label="平台账号:">
        <el-input v-model="query.account" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入平台账号" clearable></el-input>
      </el-form-item> 
      <el-form-item label="推广码:">
        <el-input v-model="query.spread_code" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入推广码" clearable></el-input>
      </el-form-item> 
      <el-form-item label="用户手机号:">
        <el-input v-model="query.mobile" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入用户手机号" clearable></el-input>
      </el-form-item> 
      <el-form-item>
        <el-select slot="label" v-model="query.search_key" placeholder="查询">
          <el-option label="用户ID" value="user_id"></el-option>
          <el-option label="姓名" value="name"></el-option>
          <el-option label="微信" value="wechat"></el-option>
          <el-option label="QQ" value="qq"></el-option>
        </el-select>
        <el-row>
          <el-col :span="14">
            <el-input v-model="query.search_val" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-button type="info" @click="resetData">重置</el-button>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-button type="primary" @click="getList()">查询</el-button>
          </el-col>

        </el-row>
      </el-form-item>
    </el-form>

    <!-- 表格 -->
    <el-table :row-class-name="tableRowClassName"  size="mini" v-loading="table.loading" :data="table.rows" border>
      <el-table-column label="用户手机号" width= "110">
          <template slot-scope="scope">
              <span>{{ scope.row.mobile }}</span>
          </template>
      </el-table-column>
      <el-table-column label="用户id">
          <template slot-scope="scope">
              <span>{{scope.row.id}}</span>
          </template>
      </el-table-column>
      <el-table-column label="平台账号" width= "150" :show-overflow-tooltip="true">
          <template slot-scope="scope">
              <span>{{scope.row.account}}</span>
          </template>
      </el-table-column>
      <el-table-column label="真实姓名">
        <template slot-scope="scope">
            <span>{{scope.row.name  }}</span>
        </template>
      </el-table-column>
      <el-table-column label="本金余额" width= "150">
        <template slot-scope="scope">
          <div  class="g-flex-align-center v-user-money-item-edit">
            <span class="g-red">{{ scope.row.principal }}</span>
            <i class="el-icon-edit" @click="changeMoneyClick(scope.row, 1)"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="佣金余额" width="150">
        <template slot-scope="scope">
          <div class="g-flex-align-center v-user-money-item-edit">
            <span class="g-blue">{{ scope.row.commission }}</span>
            <i class="el-icon-edit" @click="changeMoneyClick(scope.row, 2)"></i>
          </div>
          </template>
      </el-table-column>
      <!-- <el-table-column label="任务本金" :show-overflow-tooltip="true">
        <template slot-scope="scope">
              <span class="g-blue">{{ scope.row.task_bj }}</span>
          </template>
      </el-table-column>
      <el-table-column label="任务佣金" :show-overflow-tooltip="true">
        <template slot-scope="scope">
              <span class="g-red">{{ scope.row.task_yj }}</span>
          </template>
      </el-table-column> -->
      <el-table-column label="直属上级" width="150" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <div class="g-flex-align-center v-user-money-item-edit">
            <span class="g-red">{{ scope.row.spread | filtersSpread }}</span>
          </div>
          </template>
      </el-table-column>
      <el-table-column label="直属上级推广码" width="150" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <div class="g-flex-align-center v-user-money-item-edit">
            <span class="g-red">{{ scope.row.spread.spread_code }}</span>
          </div>
          </template>
      </el-table-column>
      <el-table-column label="推广状态" width="80" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <!--  active-text="开启" inactive-text="关闭" -->
            <el-switch v-model="scope.row.spread_status" @change="switchChange(scope.row)" :active-value="1" :inactive-value="0"  active-color="#13ce66" inactive-color="#ff4949"></el-switch>
          </template>
      </el-table-column>
      <el-table-column label="本人推广码" width="100" :show-overflow-tooltip="true">
        <template slot-scope="scope">
              <span>{{ scope.row.spread_code }}</span>
          </template>
      </el-table-column>
      <el-table-column label="直属下级人数" width="100" :show-overflow-tooltip="true">
        <template slot-scope="scope">
              <span>{{ scope.row.spread_nums }}</span>
          </template>
      </el-table-column>
      <el-table-column label="实名状态" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <span class="g-red" v-if="scope.row.real_status == 0">未实名</span>
            <span class="g-yellow" v-if="scope.row.real_status == 1">审核中</span>
            <span class="g-green" v-if="scope.row.real_status == 2">审核通过</span>
          </template>
      </el-table-column>
      <el-table-column label="状态" width="80" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <span class="g-red" v-if="scope.row.status == 0">禁用 </span>
            <span class="g-green" v-if="scope.row.status == 1 && (scope.row.black_time < parseInt(new Date().getTime() / 1000))">正常</span>
            <span class="g-green" v-if="scope.row.status == 1 && (scope.row.black_time > parseInt(new Date().getTime() / 1000))">正常 (到期时间: {{formatDate(scope.row.black_time,'yyyy-MM-dd hh:mm')}})</span>
          </template>
      </el-table-column>
      <el-table-column label="创建时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">
              <span>{{formatDate(scope.row.create_time, 'yyyy-MM-dd hh:mm')}}</span>
          </template>
      </el-table-column>
      <el-table-column label="操作" width="350" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="danger" @click="setForm(scope.row, 2)">编辑</el-button>
          <el-button size="mini" type="info" @click="apiUserAddPianziHandel(scope.row, 0)" v-if="scope.row.status == 1">封号</el-button>
          <el-button size="mini" type="primary" @click="apiUserAddPianziHandel(scope.row, 1)" v-if="scope.row.status == 0">解封</el-button>
          <el-button size="mini" type="warning" @click="addBlack(scope.row, 2)">加入/移出黑名单</el-button>
          <el-button size="mini" type="success" @click="getCode(scope.row)">获取验证码</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 弹窗 -->
    <user-list-pop ref="UserListPop" @emitSaveSuccess="getList(false)"/>

    <!-- 屏蔽 -->
    <user-list-pingbi-time-pop ref="UserListPingbiTimePop" @emitSaveSuccess="getList(false)"/>

    <el-pagination :page-sizes="[15, 30, 60, 100]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange" small hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    
    <user-list-change-money-pop ref="UserListChangeMoneyPop" @emitSaveSuccess="getList(false)"/>

    <UserListGetCodePop ref="UserListGetCodePop"/>

    <!-- 新增用户 -->
    <user-list-add-pop ref="UserListAddPop" @emitSaveSuccess="getList(false)"/>
  </el-card>
</template>

<script>
  import { formatDate } from '@/utils/index.js';
  import { apiGetBuyList, apiSetBuySpreadStatus, apiBuyUserEdit } from '@/utils/api.js'
  import UserListPop from './UserListPop.vue'
  import UserListPingbiTimePop from './UserListPingbiTimePop.vue'
  import UserListChangeMoneyPop from './UserListChangeMoneyPop.vue'
  import UserListGetCodePop from './UserListGetCodePop.vue'
  import { mixins } from '@/utils/mixins.js'
  import UserListAddPop from './UserListAddPop.vue'
  export default {
    name: 'userList',
    mixins: [mixins],
    components: { UserListPop, UserListPingbiTimePop, UserListChangeMoneyPop, UserListGetCodePop, UserListAddPop },
    data() {
      return {
        auth:{
          addBlack: this.$global.ApiList.includes(60),
          delBlack: this.$global.ApiList.includes(61),
        },

        query: { search_key: 'name', search_val: '', mobile: '', account: '', spread_code: '', date_time: [], real_status: 'ALL', status: 'ALL', page:1, limit: 15 },

        table:{ loading:false, total:0, rows:[] },

        form: false,
        radio: 1,
        areaCodeSelected: [],

        formData: {},

        commissionShow: true,
        principalShow: true,

      }
    },
    created() {
      this.getList()
    },
    activated() {
      console.log('activated')
    },
    inactivated() {
      console.log('inactivated')
    },
    filters: {
      filtersSex(sex) {
        if(sex == 1) return '男'
        return '女'
      },
      filtersSpread(obj) {
        if(!obj.id) return '无'
        if(obj.type == 1) return `买家-${obj.mobile}`
        if(obj.type == 2) return `商家-${obj.mobile}`
        if(obj.type == 3) return `代理-${obj.user_name}`
        return ''
      }
    },
    methods: {
      formatDate,
      addUser() {
        this.$refs.UserListAddPop.onShow()
      },
      // 获取验证码
      getCode(item) {
        this.$refs.UserListGetCodePop.onShow(item)
      },
       //1 修改本金余额 2修改佣金余额
      changeMoneyClick(item,status) {
        this.$refs.UserListChangeMoneyPop.onShow(item, status)
      },

      // 点击封号
      async apiUserAddPianziHandel(item, status) {
        let str = status == 0 ? '封号': '解封'
        this.$confirm(`此操作将该用户${str}, 是否继续?`, '封号', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.table.loading = true
          const { success, data } = await apiBuyUserEdit({ id: item.id, status: status}).catch(() =>  this.table.loading = false)
          this.table.loading = false
          if(!success) return
          this.$message.success(data.msg)
          this.getList(false)
        }).catch(()=>{})
      },


      // 加入黑名单
      addBlack(item, status) {
        this.$refs.UserListPingbiTimePop.onShow(item, status)
      }, 
      // 开关变化监听
      switchChange(item) {
        console.log(item)
        this.apiSetBuySpreadStatusHandel(item)
      },
      // 推广状态
      async apiSetBuySpreadStatusHandel(item) {
        this.table.loading = true
        const { success, data } = await apiSetBuySpreadStatus({ id: item.id, status: item.spread_status }).catch(() => {
          if(item.spread_status == 0) item.spread_status = 1
          if(item.spread_status == 1) item.spread_status = 0
          this.table.loading = false
        })
        if(!success) {
          if(item.spread_status == 0) return item.spread_status = 1
          if(item.spread_status == 1) return item.spread_status = 0
        }
        this.table.loading = false
        this.$message.success(data.msg)
        this.getList(false)
        console.log(data)
      },

      async getList(pageInit = true) {
        if (pageInit) this.query.page = 1
        this.table.loading = true
        const { success, data } = await apiGetBuyList(this.query).catch(() => this.table.loading = false)
        if(!success) return
        this.table.loading = false
        this.table.rows = data.rows
        this.table.total = data.total
      },

      //每页数量改变
      sizeChange(limit) {
        this.query.limit = limit
        this.getList()
      },

      //设置form
      setForm(formData = { id: ''  }, status) {
        this.formData = JSON.parse(JSON.stringify(formData))
        this.$refs.UserListPop.onShow(formData, status)
      },
      // 点击重置
      resetData() {
        this.query = { search_key: 'name', search_val: '', mobile: '', account: '', spread_code: '', date_time: [], real_status: 'ALL', status: 'ALL', page:1, limit: 15 },
        this.getList()
      }
      
    }
  }
</script>

<style lang="scss">
.v_user_list {
  .v-user-money-item-edit {
    span {
      font-size: 14px;
    }
    i {
      cursor: pointer;
      padding: 0 20px;
      font-size: 16px;
    }
  }
}

</style>