<template>
 <div class="s_user_list_pop">
    <el-dialog :close-on-press-escape="false" :title="seeStatus == 1 ? '用户详情': '查看详情'" class="s-task-dialog" top="5vh" width="1000px" :close-on-click-modal="false" :visible.sync="show">
      <el-form :model="formData" label-width="140px" size="mini">
         <el-row>
          <el-col :span="8">
            <el-form-item label="用户ID:">
              <span>{{ formData.id }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="用户手机号:">
              <span>{{formData.mobile}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="佣金余额:">
              <span>{{formData.commission}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="本金余额:">
              <span>{{formData.principal}}</span>
            </el-form-item>
          </el-col>
        </el-row>
         <el-row>
          <el-col :span="8">
            <el-form-item label="用户昵称:">
              <span>{{formData.nick_name}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="用户头像:">
              <el-image style="width: 100px; height: 100px" fit="cover" :src="formData.head_img" :preview-src-list="[formData.head_img]"></el-image>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="实名状态:">
              <span class="g-red" v-if="formData.real_status == 0">未实名</span>
              <span class="g-yellow" v-if="formData.real_status == 1">审核中</span>
              <span class="g-green" v-if="formData.real_status == 2">审核通过</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="推广状态:">
              <span class="g-red" v-if="formData.spread_status == 0">关闭</span>
              <span class="g-green" v-if="formData.spread_status == 1">开启</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="推广码:">
              <span>{{formData.spread_code}}</span>
            </el-form-item>
          </el-col>
           <el-col :span="12">
            <el-form-item label="平台账号:">
              <span>{{formData.account}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="状态:">
              <span class="g-red" v-if="formData.status == 0">禁用</span>
              <span class="g-green" v-if="formData.status == 1">启用</span>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="密码:">
              <el-input placeholder="不修改不填写此项" v-model="formData.password" show-password></el-input>
              <span class="g-red">6-20的数字字母组合</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="接单金额设置:">
              <el-input placeholder="请填写用户接单金额(元)" oninput="value=value.replace(/[^0-9.]/g,'')" v-model="formData.price_limit"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div v-if="formData.real_status == 2">
          <el-row>
            <el-col :span="8">
              <el-form-item label="身份证号码:">
                <span>{{formData.card_id}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="14">
              <el-form-item label="用户地址:">
                <span>{{ formData.region }} - {{ formData.address }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="身份证正面照:">
                <el-image style="width: 100px; height: 100px" fit="cover" :src="formData.card_front" :preview-src-list="[formData.card_front]"></el-image>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="身份证反面照:">
                <el-image style="width: 100px; height: 100px" fit="cover" :src="formData.card_back" :preview-src-list="[formData.card_back]"></el-image>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="微信:">
                <span>{{formData.wechat}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="14">
              <el-form-item label="QQ:">
                <span>{{ formData.qq }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <el-row>
          <el-col :span="8">
            <el-form-item label="登录时间:">
              <span>{{ formatDate(formData.login_time, 'yyyy-MM-dd hh:mm:ss') }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item label="创建时间:">
              <span>{{ formatDate(formData.create_time, 'yyyy-MM-dd hh:mm:ss') }}</span>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="结佣方式:">
            <el-radio-group v-model="formData.fk">
              <el-radio :label="0">货返</el-radio>
              <el-radio :label="1">立返</el-radio>
            </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="show = false" v-show="seeStatus === 2">取 消</el-button>
        <el-button type="primary" @click="apiBuyUserEditHandel" v-show="seeStatus === 2">确定</el-button>
      </div>
    </el-dialog>
 </div>
</template>

<script>
  import { formatDate } from '@/utils/index.js';
  import { apiBuyUserEdit } from '@/utils/api.js'
export default {
  data() {
    return {
      seeStatus: 1,
      show: false,
      formData: {
        password: '',
        price_limit: '',
      },
    }
  },
  methods: {
    formatDate,
    // status //1 审核 2查看
    onShow(data, status) {
      console.log(status)
      this.seeStatus = status
      console.log(this.formData)
      this.formData.password = ''
      this.formData.price_limit = ''
      this.formData = Object.assign({}, this.formData, data)
      this.show = true
    },
    
    // 修改信息用户信息
    async apiBuyUserEditHandel() {
      let loading = this.$loading()
      const { success, data } = await apiBuyUserEdit(this.formData).catch(() => loading.close())
      loading.close()
      if(!success) return
      this.$message.success(data.msg)
      this.$emit('emitSaveSuccess')
      this.show = false
    },
    onClose() {
      this.show = false
    }
  }
}
</script>

<style lang='scss'>

</style>